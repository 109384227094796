var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "iq-button",
    {
      class: [_vm.styles, { disabled: _vm.disabled }],
      staticStyle: { "max-width": "100%", width: "100%" },
      attrs: { icon: _vm.icon, color: "main-dark", size: "fullWidthMedium" },
      on: { onClick: _vm.clicked },
    },
    [_vm._v(" " + _vm._s(_vm.title) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }